import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from 'antd';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container">
      <h1>404 - PAGE NON TROUVÉ</h1>
      <Button>
        <Link to="/" style={{ color: '#8ab7ab' }}>RETOURNER À L'ACCUEIL</Link>
      </Button>
    </div>
  </Layout>
)

export default NotFoundPage
